<template>
  <div v-if="mf && mf != {}">
    <v-row
      v-if="uid"
      class="mb-2 p-3 pushxl hidescroll"
      style="flex-wrap: nowrap;"
    >
      <template v-for="item in mftabs">
        <div
          class="userprofile_box white--text listitem p-4 py-4 primary lighten-1 pushxl"
          :style="$root.mobile ? 'width: 55vw;min-width: 55vw;' : 'min-width:270px'"
          :key="item.label"
        >
          <div class="inner pushxl">
            <unicon
              :name="item.icon"
              fill="#FFFFFF"
              height="20"
              class="pushx"
            ></unicon>
            <span>{{ item.text }}</span>
          </div>
          <v-spacer /><span class="font-weight-black text-h5">{{
            mf[item.label].length
          }}</span>
        </div>
      </template>
    </v-row>
    <v-list>
      <v-form ref="form" v-model="valid">
        <v-list-item>
          <v-list-item-content>
            <div class="userprofile">
              <div v-if="!$root.mobile" class="title pushxl">
                Full Name
              </div>
              <v-text-field
                :disabled="!doedit"
                hide-details="auto"
                dense
                :outlined="doedit"
                :rules="nameRules"
                :solo="!doedit"
                flat
                v-model="mf.full_name"
              />
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <div class="userprofile">
              <div v-if="!$root.mobile" class="title pushxl">
                Gender
              </div>
              <v-select
                :disabled="!doedit"
                hide-details="auto"
                dense
                :outlined="doedit"
                :rules="requiredFeildRule"
                :solo="!doedit"
                flat
                v-model="mf.gender"
                :items="['male', 'female']"
              />
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <div class="userprofile">
              <div v-if="!$root.mobile" class="title pushxl">
                Date Of Birth
              </div>
              <v-text-field
                :disabled="!doedit"
                hide-details="auto"
                dense
                :rules="bdayrule"
                v-mask="'##/##/####'"
                :outlined="doedit"
                :solo="!doedit"
                flat
                v-model="mf.birthday"
              />
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <div class="userprofile">
              <div v-if="!$root.mobile" class="title pushxl">
                Height
              </div>
              <v-text-field
                :disabled="!doedit"
                hide-details="auto"
                :rules="requiredFeildRule"
                dense
                :outlined="doedit"
                :solo="!doedit"
                flat
                v-model="mf.height"
                suffix="cm"
              />
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <div class="userprofile">
              <div v-if="!$root.mobile" class="title pushxl">
                Weight
              </div>
              <v-text-field
                :disabled="!doedit"
                :rules="requiredFeildRule"
                hide-details="auto"
                dense
                :outlined="doedit"
                :solo="!doedit"
                flat
                v-model="mf.weight"
                suffix="kg"
              />
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <div class="userprofile">
              <div v-if="!$root.mobile" class="title pushxl">
                Blood Type
              </div>
              <v-select
                :disabled="!doedit"
                :rules="requiredFeildRule"
                hide-details="auto"
                dense
                :outlined="doedit"
                :solo="!doedit"
                flat
                v-model="mf.blood_type"
                :items="bloodTypes"
                return-object
              />
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-form>
    </v-list>
    <v-card-actions
      v-if="mf.id"
      class="p-0"
      style="height:64px;display:flex;width:100%;justify-content: center;align-items: center;background: white;
    "
    >
      <v-btn
        :loading="updating"
        :disabled="updating"
        @click="update"
        block
        elevation="0"
        rounded
        large
        color="primary"
      >
        Save Medical Updates
      </v-btn>
    </v-card-actions>
  </div>
  <v-row align="center" justify="center" style="height:10vh" v-else>
    <v-progress-circular color="primary" indeterminate />
  </v-row>
</template>
<script>
export default {
  name: "MedicalProfile",
  components: {},
  props: ["uid"],
  data() {
    return {
      doedit: true,
      mf: null,
      updating: false,
      bloodTypes: ["O+", "O-", "B+", "B-", "A+", "A-", "AB+", "AB-"],
      valid: false,
      mftabs: [
        {
          label: "appointments",
          text: this.$t("MENU.appointment"),
          icon: "clock",
        },
        {
          label: "history",
          text: this.$t("MF.medicalHistoryLabel"),
          icon: "history",
        },
        {
          label: "vitals",
          text: this.$t("MF.vitalslabel"),
          icon: "heartbeat",
        },
        {
          label: "diagnoses",
          text: this.$t("MF.diagnosesLabel"),
          icon: "user-md",
        },
        {
          label: "prescription",
          text: this.$t("MF.prescriptionLabel"),
          icon: "capsule",
        },
        {
          label: "files",
          text: this.$t("MF.filesLabel"),
          icon: "file-copy-alt",
        },
      ],
      bdayrule: [
        (v) => !!v || "Birthday is required",
        (v) => {
          const pattern = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;
          return pattern.test(v) || "Birthday must be valid";
        },
      ],
      nameRules: [
        (v) => !!v || this.$t("AUTH.REG_STEPS.STEP1.validation[0]"),
        (v) => {
          const pattern = /^[^\s]+( [^\s]+)+$/;
          return (
            pattern.test(v) || this.$t("AUTH.REG_STEPS.STEP1.validation[1]")
          );
        },
      ],
      requiredFeildRule: [(v) => !!v || "Required Field"],
    };
  },
  beforeMount() {},
  activated() {
    console.log("activating");
    if (this.mf == null) {
      if (this.uid) this.loadFile();
      else this.mf = {};
    }
  },
  deactivated() {
    console.log("deactivating");
    this.mf = null;
  },
  mounted() {},
  methods: {
    async update() {
      console.log("udpating and valid state is", this.isValid())
      if (this.isValid()) {
        this.updating = true;
        const payload = {
          full_name: this.mf.full_name,
          gender: this.mf.gender,
          blood_type: this.mf.blood_type,
          weight: this.mf.weight,
          height: this.mf.height,
        };
        const splitbday = this.mf.birthday.split("/");
        payload.birthday = splitbday[2] + "-" + splitbday[1] + "-" + splitbday[0];
        console.log("update mf payload",payload);

        await this.$file.updatePatient(this.mf.id,payload).then((data) => {
          if(data.status == 200)
          {
            this.updating = false;
          }
        })
      }
    },
    reset() {
      console.log("resetting mf form");
      this.$refs.form.resetValidation();
      this.mf = {
        full_name: "",
        gender: "male",
        birthday: "",
        blood_type: "",
        weight: "",
        height: "",
      };
    },
    isValid() {
      return this.$refs.form.validate();
    },
    getMF() {
      return this.mf;
    },
    async loadFile() {
      await this.$file.getItemByID(this.uid, true).then((data) => {
        var splitbday = data.birthday.split("-");
        data.birthday = splitbday[2] + "/" + splitbday[1] + "/" + splitbday[0];
        this.mf = data;
      });
    },
  },
  computed: {},
};
</script>
